<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="$t('TERMS.categories_mitre')"
    :description="$t('PAGES.DESCRIPTIONS.mitre_description')"
    has-back-button
    ></SectionHeader>

    <p></p>

    <div class="pt-4 px-5">
      <WrapperForMitreMatrix />
    </div>

  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import WrapperForMitreMatrix from '@/components/WrapperForMitreMatrix'

export default {
  name: 'MitreByUser',
  components: { SectionHeader, WrapperForMitreMatrix },
  computed: {
    isLoading () { return false }
  }
}
</script>

<style lang="scss" scoped>

</style>
